import e from "cors";
import { ref } from "vue";

import { hostIP } from "./constants";

export async function getUserIdAuth(token) {
    const msg = {
        "token": token
    };
    const request = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(msg)
    };
    const response = await fetch(`${hostIP}/check_auth`, request);
    const is_success = (response.status === 200) ? true : false;

    const accountID = ref(null);
    const isAdmin = ref(false);
    if (is_success) {
        try {
            const data = await response.json();
            accountID.value = data.user_id;
            isAdmin.value = data.auth;
        }
        catch (error) {

        }
    }
    return {
        ID: accountID.value,
        isAdmin: isAdmin.value
    };
}

export async function getJobGroupUsers (jobId) {
    const res = ref(null)
    const msg = {
                "job_id": jobId,
            }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/get_job_group_users`, request)
    const is_success = (response.status === 200) ? true : false;
    if (is_success) {
        const response_json = await response.json()
        try {
            res.value = response_json.results
        }
        catch (error)
            {console.log(error)}
    }
    return res.value
}

export async function sendWarning (jobId, users) {
    const isSuccess = ref(false)
    const msg = {
                "job_id": jobId,
                "users": Object.values(users)
            }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/send_warning`, request)
    const is_success = (response.status === 200) ? true : false;
    if (is_success) {
        try {
            const response_json = await response.json()
        isSuccess.value = true
        }
        catch (error) {
            console.log(error)
        }
    }
    return isSuccess.value
}

export async function searchUsers (userInfo) {
    const res = ref(null)
    const msg = {
                "user_info": userInfo,
            }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/search_user`, request)
    const is_success = (response.status === 200) ? true : false;
    if (is_success) {
        try{
            const response_json = await response.json()
        res.value = response_json.results
        }
        catch (error) {
            console.log(error)
        }
    }
    return res.value
}

export async function addUser (groupId, userId) {
    const msg = {
                "group_id": groupId,
                "user_id": userId
            }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/add_user_to_group`, request)
    const is_success = (response.status === 200) ? true : false;
    return is_success
}

export async function getAllUserData(token) {
    const msg = {
        "token": token,
    }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/get_all_user`, request)
    const is_success = (response.status === 200) ? true : false;
    const data = [];
    if (is_success){
        try {
            const response_json = await response.json()
            const group_user_data = JSON.parse(response_json.all_user_data)
            const n_row = response_json.n_data
            for (let i=0; i<n_row; i++) {
                data.push({
                    UserId: group_user_data.UserId[i],
                    UserName: group_user_data.UserName[i],
                    Role: group_user_data.Role[i],
                    IsAuth: group_user_data.isAuth[i],
                    RoleChange: false,
                    OriginRole: group_user_data.Role[i]
                })
            }
        }
        catch (error){
            console.log(error)
        }
        
    }
    return {
        data
    }
} 


export async function getGroupData(token) {
    const msg = {
        "token": token,
    }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/get_all_groups`, request)
    const is_success = (response.status === 200) ? true : false;
    const data = [];
    if (is_success){
        try {
            const response_json = await response.json()
            const groups_data = JSON.parse(response_json.groups_data)
            const n_row = response_json.n_data
            for (let i=0; i<n_row; i++) {
                data.push({
                    GroupId: groups_data.GroupId[i],
                    GroupName: groups_data.GroupName[i],
                    CreatorId: groups_data.CreatorId[i],
                })
            }
        }
        catch (error) {
            console.log(error)
        }
        
    }
    return {
        data
    }
}

export async function getUserGroup(token) {
    const data = []
    const msg = {"token": token}
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/get_user_groups`, request)
    const is_success = (response.status === 200) ? true : false;
    
    if (is_success){
        try {
            const response_json = await response.json();
            const n_data = response_json.n_data
            const groups_data = JSON.parse(response_json.groups_data)
            for (let i=0; i<n_data; i++) {
                data.push({
                    text: groups_data.GroupName[i],
                    key: groups_data.GroupId[i]
                })
            }
        }
        catch (error) {
            console.log(error)
        }
        
    }
    return data
}


export async function checkGroupAuth (token, groupId) {
    const msg = {
        "token": token,
        "group_id": groupId
    }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/check_group_auth`, request)
    const is_success = (response.status === 200) ? true : false;

    return is_success
}

export async function terminateJob (jobId) {
    const msg = {
        "job_id": jobId
    }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/terminate_job`, request)
    const is_success = (response.status === 200) ? true : false;

    return is_success
}

export async function getJobProcessPercentage (jobId) {
    const msg = {
        "job_id": jobId
    }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const percentage = ref(0)
    let jobStatus = ''
    const response = await fetch(`${hostIP}/process_status`, request)
    const is_success = (response.status === 200) ? true : false;
    if (is_success) {
        const response_json = await response.json();
        percentage.value = response_json.process_status
        jobStatus = response.job_status
        return {
            percentage: response_json.process_status,
            jobStatus: response_json.job_status
        }
    }
    return {
        percentage: 0,
        jobStatus: ''
    }
}