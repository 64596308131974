<template>
    <div class="container">
        <div v-if="userId">
            <myTable :ID="userId" api="get_warning_job_data" :isWarningTable="true"/>
        </div>     
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies"
import { getUserIdAuth } from '@/js/asyncFunctions';

import myTable from "@/components/MyTable.vue"

let { cookies } = useCookies();

export default {
    name: 'warningJob',
    props: {
        
    },
    components: {
        myTable,
    },

    data () {
        return {

        }
    },

    setup() {
        let token = ''
        token = cookies.get('seeng-access-token')
        const userId = ref(null)

        const fetchData = async () => {
            return await getUserIdAuth(token)
        }

        onMounted(async () => {
            const res = await fetchData()
            userId.value = res.ID
        })
        return {
            userId,
        }
    },

    methods: {
        checkCookie() {
        if (!cookies.isKey('my-cookie-token')){
            const router = useRouter()
            router.push('/login')
        }
        },
    },

    mounted() {

    },
}
</script>

<style>
#myJobDiv{
  position: relative;
  float: left;
  width: calc(100% - 210px);
  margin-left: 5px;
}
</style>
