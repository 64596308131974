<template>
    <Transition>
        <div class="menuDiv" :style="{height: menuHeight,}">
            <div class="menuIconDiv">
                <img src="@/assets/menuIcon.svg" class="menuIcon" @click="clickShowMenu"/>
            </div>
            <el-row class="tac" :style="{display: displayMenu}">
                <el-col :span="12">
                    <el-menu
                    active-text-color="#ffd04b"
                    background-color="#545c64"
                    class="elmenu"
                    :default-active="active_idx"
                    text-color="#fff"
                    >
                        <el-menu-item index="groups" @click="clickViewGroups">
                            <!-- <el-icon><icon-menu /></el-icon> -->
                            <el-icon></el-icon>
                            <span>Plants</span>
                        </el-menu-item>

                        <el-menu-item index="my_job" @click="clickMyJob">
                            <!-- <el-icon><icon-menu /></el-icon> -->
                            <el-icon></el-icon>
                            <span>Switchboards</span>
                        </el-menu-item>

                        <el-menu-item index="warn_job" @click="clickWarnJob">
                            <!-- <el-icon><icon-menu /></el-icon> -->
                            <el-icon></el-icon>
                            <span>Warning</span>
                        </el-menu-item>


                        <el-sub-menu index="auth" v-if="isAdmin">
                            <template #title>
                                <!-- <el-icon><location /></el-icon> -->
                                <el-icon></el-icon>
                                <span>Admin</span>
                            </template>
                                <el-menu-item index="allGroup" @click="clickManageGroups" :style="{minWidth: '0px'}">All Plants</el-menu-item>
                                <el-menu-item index="allJob" @click="clickManageJobs" :style="{minWidth: '0px'}">All Switchboards</el-menu-item>
                                <el-menu-item index="allUser" @click="clickManageUsers" :style="{minWidth: '0px'}">All Users</el-menu-item>
                        </el-sub-menu>
                    
                        <el-menu-item index="profile" @click="clickProfile">
                            <!-- <el-icon><icon-menu /></el-icon> -->
                            <el-icon></el-icon>
                            <span>Profile</span>
                        </el-menu-item>
                        <el-menu-item index="logOut" @click="clickLogOut">
                            <!-- <el-icon><icon-menu /></el-icon> -->
                            <el-icon></el-icon>
                            <span>Log Out</span>
                        </el-menu-item>
                    </el-menu>
                </el-col>
            </el-row>
        </div>
    </Transition>
</template>
  
<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies";
import { getUserIdAuth } from "@/js/asyncFunctions";
import { loginIP, platformIP, hostIP } from "@/js/constants.js";

let { cookies } = useCookies();

export default {
    name: 'Menu',
    props: {
        active_idx: {
            type: String,
            default: 'my_job'
        }
    },
    setup() {
        const router = useRouter()
        let token = ''
        token = cookies.get('seeng-access-token')

        const isAdmin = ref(false)

        const fetchData = async () => {
            return await getUserIdAuth(token)
        }

        onMounted(async () => {
            const res = await fetchData()
            isAdmin.value = res.isAdmin
        })

        return {
            router,
            isAdmin,
        }
    },
    data () {
        return {
            showMenuToggle: false,
            menuHeight: '100%',
            displayMenu: 'block',
            showHeight: '100%',
            hiddenHeight: '100%'

        }
    },
    created () {
        if (window.innerWidth < 1200 && window.innerWidth > 500) {
            this.showHeight = '430px'
            this.hiddenHeight = '70px'
            this.menuHeight = '70px';
            this.displayMenu = 'none';
        }
        else if (window.innerWidth < 500) {
            this.showHeight = '430px'
            this.hiddenHeight = '50px'
            this.menuHeight = '50px'
            this.displayMenu = 'none';
        }
        else {
            this.showHeight = '100%'
        }
    },
    methods: {
        clickMyJob() {
            this.router.push('/my_job')
        },
        clickWarnJob() {
            this.router.push('/warn_job')
        },
        clickViewGroups() {
            this.router.push('/groups')
        },
        clickManageJobs () {
            this.router.push('/adminalljob')
        },
        clickManageUsers () {
            this.router.push('/adminalluser')
        },
        clickManageGroups() {
            this.router.push('/adminallgroup')
        },
        clickProfile() {        
            this.router.push('/profile')
        },
        async clickLogOut() {
            const token = cookies.get('seeng-access-token');
            const msg = {
                "token": token,
            }

            const request = {
                method: "POST",
                headers: {
                    // "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(msg)
            }
            // const response = await fetch(`${loginIP}/api/logout`, request)
            const response = await fetch(`${hostIP}/logout`, request)

            cookies.remove('seeng-access-token');
            this.router.push('/login')
            
        },

        clickShowMenu () {
            console.log('click')
            this.showMenuToggle = !this.showMenuToggle
            if (this.showMenuToggle) {
                this.menuHeight = this.showHeight
                this.displayMenu = 'block'
            }
            else {
                this.menuHeight = this.hiddenHeight
                this.displayMenu = 'none'
            }
        }
    },

    mounted() {
        
  }
}
</script>

<style scoped>
ul {
    border: 0px;
}
.tac {
    top: 40px;
    height: calc(100% - 70px);
}
.elmenu {
    width:170px;
    height: 100%;
}
.menuDiv {
    width: 170px;
    height: 100%; 
    background-color: #545c64; 
    float: left;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
}

.menuIconDiv {
    position: relative;
    height: 70px;
    z-index: 1000;
}
.menuIcon {
    display: none;
    position: relative;
    float: left;
    z-index: 1000;
}

@media (max-width: 1200px) {
    .menuDiv{
        position: absolute;
    }
    .menuIcon {
        display: block;
        width: 40px;
        top: 50%;
        left: 50px;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .tac {
        top: 0px;
    }

}

@media (max-width: 500px){
    .menuDiv {
        height: 50px;
    }
    .menuIconDiv{
        height: 50px;
    }
    .menuIconDiv img {
        left: 5%
    }
}
</style>
  