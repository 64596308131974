<template>
    <div class="container">
      <div v-if="!addGroupToggle">
            <button style="float:right" @click="clickAddGroup">Add Plant</button>
            <groupTable/>
      </div>
      <div v-if="addGroupToggle">
        <addNewGroup v-model:addGroupToggle="addGroupToggle"/>
      </div>
    </div>
</template>
  
<script>
import groupTable from "@/components/GroupTable.vue"
import addNewGroup from "@/components/AddNewGroup.vue"


export default {
name: 'userGroups',
props: {

},
components: {
    groupTable,
    addNewGroup,
},

data () {
    return {
        addGroupToggle: false,
    }
},

setup() {

},

methods: {
    clickAddGroup () {
        this.addGroupToggle = !this.addGroupToggle
        }
    }
}
</script>

<style>
#myJobDiv{
position: relative;
float: left;
width: calc(100% - 210px);
margin-left: 5px;
}
</style>
