<template>
    <Menu active_idx="groups"/>
    <userGroups/>
  </template>
  
  <script>
  // @ is an alias to /src
  import userGroups from '@/components/UserGroups.vue'
  import Menu from '@/components/Menu.vue'
  
  export default {
    name: 'GroupsListView',
    components: {
      Menu,
      userGroups
    }
  }
  </script>