<template>
    <div id="titleDiv">
        Create New Switchboard
    </div>
        <div class="formContainer">
            <div class="addJobDiv">
                <inputBox inputKey="Name" @emitInputValue="getJobName"/>
                <inputBox inputKey="Iscs" inputType="number" inputUnit="A" @emitInputValue="getIscs"/>
                <inputBox inputKey="&#945" details="current temperture coefficient" inputType="number" inputUnit="A/&#176C" @emitInputValue="getAlpha"/>
                <inputBox inputKey="Imps" inputType="number" inputUnit="A" @emitInputValue="getImps"/>
                <inputBox inputKey="Voc" details="open circuit voltage at STC" inputType="number" inputUnit="V" @emitInputValue="getVoc"/>
                <inputBox inputKey="&#946" details="voltage temperture coefficient" inputType="number" inputUnit="V&#176C" @emitInputValue="getBeta"/>
                <inputBox inputKey="Vmps" inputType="number" inputUnit="V" @emitInputValue="getVmps"/>
                <inputBox inputKey="Num Panels" details="number of panels" inputType="number" @emitInputValue="getNPanels"/>
                <inputBox inputKey="Num Rows" details="number of rows" inputType="number" @emitInputValue="getNRows"/>
                <selectGroup @emitInputValue="getGroupId" :initSelectedValue="initGroupId"/>
            </div>
            <div class="extraInfo">
                <div class="dataInfoDiv">
                    <div id='dataSorceTitle'>
                        Data Source
                    </div>
                    <div class="urlTitle">
                        Radiance:
                    </div>
                    <linkDiv url="https://joint-research-centre.ec.europa.eu/pvgis-online-tool/pvgis-tools/hourly-radiation_en" 
                    textMsg="EU Science Hub: Hourly radiation"/>
                    <linkDiv url="https://joint-research-centre.ec.europa.eu/pvgis-online-tool/pvgis-data-download/pvmaps_en" 
                    textMsg="EU Science Hub: PVMAPS"/>
                    <linkDiv url="https://power.larc.nasa.gov/data-access-viewer/" 
                    textMsg="POWER Data Access Viewer"/>
                    <linkDiv url="https://nsrdb.nrel.gov/" 
                    textMsg="National Solar Radiation Database"/>
                </div>
                <div class="dataInfoDiv">
                    <div class="urlTitle">
                        Meteo:
                    </div>
                    <linkDiv url="https://worldweather.wmo.int/en/home.html" 
                    textMsg="World Weather Information Service"/>
                    <linkDiv url="https://www.windy.com/" 
                    textMsg="Windy"/>
                </div>
                <div class="dataInfoDiv">
                    <div class="urlTitle">
                        Panels Database:
                    </div>
                    <linkDiv url="https://www.enfsolar.com/pv/panel" 
                    textMsg="ENF solar"/>
                    <linkDiv url="https://www.secondsol.com/en/datasheet/modules/" 
                    textMsg="SECONDSOL"/>
                </div>
            </div>
        </div>
    <div class="buttonDiv">
        <button @click="clickAddJob">Add</button>
        <button @click="clickCancel">Cancel</button>
    </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import { useCookies } from "vue3-cookies"
import { ref } from "vue"

import { hostIP } from '@/js/constants'

import inputBox from '@/components/InputBox.vue'
import linkDiv from "@/components/LinkDiv"
import selectGroup from "@/components/SelectGroup.vue"

let { cookies } = useCookies();

export default {
    name: "addNewJob",
    components: {
        inputBox,
        linkDiv,
        selectGroup
    },
    props: {
        addJobToggle: Boolean,  // use to show this window or not
        initGroupId: {
            type: String,
            default: ''
        }

    },
    data() {
        return {
            jobName: '',
            Iscs: '',
            alpha: '',
            Imps: '',
            Voc: '',
            beta: '',
            Vmps: '',
            nPanels: '',
            nRows: '',
            groupName: '',
            groupId: ''
        }
    },
    emits: [
        'update:addJobToggle',
    ],

    setup() {
        
    },

    methods: {
        emitAddJobToggle (){
            this.$emit('update:addJobToggle', !this.addJobToggle)
        }, 

        async clickAddJob () {
            if (this.jobName == ''){
                ElMessageBox.alert('Please specify switchboard name', 'Warning', {
                    confirmButtonText: 'OK',
                })
            }
            else {
                const addJobSuccess = ref(false)
                const token = cookies.get('seeng-access-token')
                if (token) {
                    const msg = {
                        "token": token,
                        "job_name": this.jobName,
                        "Iscs": this.Iscs,
                        "alpha": this.alpha,
                        "Imps": this.Imps,
                        "Voc": this.Voc,
                        "beta": this.beta,
                        "Vmps": this.Vmps,
                        "n_panels": this.nPanels,
                        "n_rows": this.nRows,
                        "group_id": this.groupId
                    }
                    const request = {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(msg)
                    }
                    try {
                        const response = await fetch(`${hostIP}/create_new_job`, request)
                        const is_success = (response.status === 200) ? true : false;
                        if (is_success) {
                            addJobSuccess.value = true
                        }
                    }
                    catch (error){
                        console.log(error)
                    }
                }
                if (addJobSuccess.value) {
                    ElMessageBox.alert('Add switchboard successfully', 'Message', {
                        confirmButtonText: 'OK',
                    })
                    this.emitAddJobToggle()
                }
                else {
                    ElMessageBox.alert('Fail creating switchboard', 'Warning', {
                        confirmButtonText: 'OK',
                    })
                }
            }
        },

        clickCancel () {
            this.emitAddJobToggle()
        },

        getJobName (value) {
            this.jobName = value
        },
        getIscs (value) {
            this.Iscs = value
        },
        getAlpha (value) {
            this.alpha = value
        },
        getImps (value) {
            this.Imps = value
        },
        getVoc (value) {
            this.Voc = value
        },
        getBeta (value) {
            this.beta = value
        },
        getVmps (value) {
            this.Vmps = value
        },
        getNPanels (value) {
            this.nPanels = value
        },
        getNRows (value) {
            this.nRows = value
        },
        getGroupId (value){
            this.groupId = value
        }
    },

    mounted() {
        this.groupId = this.initGroupId
    }

}
</script>

<style scoped>
#titleDiv {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.formContainer{
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid black;
    border-radius: 5px;
    width: 85%;
    margin-bottom: 20px;
}

.addJobDiv {
    float: left;
    position: relative;
    width: calc(99% - 260px);

    margin-top: 10px;
    margin-bottom: 20px;
}

.extraInfo {
    float: right;
    position: relative;
    width: 260px;
    margin-top: 10px;
}

.dataInfoDiv{
    position: relative;
    padding-bottom: 20px;
}

.urlTitle{
    display: flex;
}

.buttonDiv{
    position: relative;
    float: left;
    width: 100%;
}

#dataSorceTitle{
    font-size: 25px; 
    display: flex; 
    padding-bottom: 5px;
}

@media (max-width: 950px) {
    .addJobDiv {
        width: 100%;
    }
    .extraInfo {
        width: calc(100% - 24px);
        padding-left: 24px;
    }
}

@media (max-width: 500px){
    #titleDiv {
        font-size: 20px;
        margin-top: 10px;
    }
    #dataSorceTitle{
        font-size: 20px;
    }
    .formContainer {
        width: 98%;
        margin-top: 0px;
    }
    .extraInfo {
        padding-left: 1px;
        float:left;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>