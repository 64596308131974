<!-- AuthCallback.vue -->
<template>
    <div>Logging you in...</div>
</template>

<script>

import { hostIP } from '@/js/constants';
import { useCookies } from "vue3-cookies"

let { cookies } = useCookies();

export default {
  name: 'AuthCallback',
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.handleAuthentication(code);
    } else {
      // window.location.href = 'http://localhost:7001/prompt/test_app'
      this.$router.push({ name: 'login' }); // or error page
    }
  },
  methods: {
    async handleAuthentication(code) {
      try {
        const msg = {"code": code}
        const request = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(msg)
        }
        const response = await fetch(`${hostIP}/authenticate`, request)
        const is_success = (response.status === 200) ? true : false;
        if (is_success){
            const data = await response.json();
            const access_token = data.access_token
            const expires_in = data.expires_in
            cookies.set('seeng-access-token', access_token, expires_in);
            this.$router.push({ name: 'home' });
        }
        else {
            this.$router.push({ name: 'login' });
        }

      } catch (error) {
        console.error('Authentication failed:', error);
        this.$router.push({ name: 'login' }); // or error page
      }
    }
  }
};
</script>