<template>
    <div class="linkContainer">
        <a :href="url">
            <div class="linkDiv" @click="goToLink">
                {{ textMsg }}
            </div>
        </a>
    </div>
</template>
<script>
export default {
    name: "linkDiv",
    props: {
        url: String,
        textMsg: String
    },
    methods: {
        goToLink() {
            window.open(this.url, "_blank");
        }
    }
};
</script>

<style scoped>
.linkContainer {
    display: flex;
    align-items: center;
    margin-top: 7px;
}

.linkDiv {
    text-align: center;
    cursor: pointer;
}
</style>