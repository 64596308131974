<template>
  <div class='searchDiv' style="text-align: left">
    <input class="input-style" v-model="searchTerm" placeholder="Filter by Switchboard ID, Name or Creator Id"/>
    <button class="buttonStyle" @click="filterSearch">Search</button>
  </div>
    
  <table-lite class="datatable"
    :is-slot-mode="true"
    :is-loading="table.isLoading"
    :is-re-search="table.isReSearch"
    :columns="table.columns"
    :rows="table.rows"
    :total="table.totalRecordCount"
    :sortable="table.sortable"
    :messages="table.messages"
    @do-search="doSearch"
    @is-finished="tableLoadingFinish"
    @return-checked-rows="updateCheckedRows"
  >
    <template v-slot:Operation="data">
      <button type="button" @click="clickView(data.value.JobId)">View</button>
      <button type="button" @click="clickMap(data.value.JobId)">Map</button>
      <button v-if="!isWarningTable" type="button" @click="clickDelete(data.value.JobId)">Delete</button>
      <button v-if="isWarningTable" type="button" @click="clickSolved(data.value.JobId)">Solved</button>
    </template>
  </table-lite>
  <div class="mask" v-if="showDeleteJob" @click="toggleDeleteJob">
      <div class="messageBox" @click.stop="">
          <div class="message" style="margin-top: 20px">Do you want to Delete Job: </div>
          <div class="message" style="margin-top: 5px">{{ deleteJobId }}</div>
            <button @click="clickDeleteJob" style="margin-top: 20px; margin-bottom: 20px">Yes</button>
            <button @click="clickCancel" style="margin-top: 20px; margin-bottom: 20px">No</button>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref} from "vue";
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies"
import { hostIP, mapIP } from "@/js/constants";
import { translateStatus } from "@/js/functions"
import TableLite from "vue3-table-lite";

let { cookies } = useCookies();

async function getMyJobData(ID, api) {
  const msg = {
                "ID": ID,
            }
  const request = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(msg)
  }
  const response = await fetch(`${hostIP}/${api}`, request)
  const is_success = (response.status === 200) ? true : false;
  let data = [];
  if (is_success){
    const response_json = await response.json()
    const jobs_data = JSON.parse(response_json.jobs_data)
    const n_row = response_json.n_data
    for (let i=0; i<n_row; i++) {
      let timestamp = jobs_data.Date[i]
      var date = new Date(timestamp)
      const createDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      data.push({
        JobId: jobs_data.JobId[i],
        JobName: jobs_data.JobName[i],
        CreateDate: createDate,
        GroupName: jobs_data.GroupName[i],
        CreatorId: jobs_data.CreatorId[i],
        Status: jobs_data.Status[i] 
      })
    }
  }  
  return {
    data
  }
}


async function deleteJobfunc(token, jobId) {
  const msg = {
                "token": token,
                "job_id": jobId
              }
  const request = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(msg)
  }
  const response = await fetch(`${hostIP}/delete_job`, request)
  const is_success = (response.status === 200) ? true : false;
  return {
    is_success
  }
}

async function solvedJobFunc(token, jobId) {
  const msg = {
                "token": token,
                "job_id": jobId
              }
  const request = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(msg)
  }
  const response = await fetch(`${hostIP}/solved_job`, request)
  const is_success = (response.status === 200) ? true : false;
}

export default defineComponent({
  name: "myTable",
  components: { 
    TableLite,
  },
  
  props: {
    ID: '',
    api: String,
    isWarningTable: {
      type: Boolean,
      default: false
    },
  },
  
  emits: [
    'emitJobId'
  ],

  data () {
    return{
      showDeleteJob: false,
      deleteJobId: ''
    }
  },

  setup() {
    let token = ''
    token = cookies.get('seeng-access-token')

    const router = useRouter()
    const table = reactive({
      isLoading: false,
      isReSearch: false,
      columns: [
        {
          label: "Switchboard ID",
          field: "JobId",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Switchboard",
          field: "JobName",
          width: "3%",
          sortable: true,
        },
        {
          label: "Date",
          field: "Date",
          width: "4%",
          sortable: true,
        },
        {
          label: "Plant",
          field: "GroupName",
          width: "3%",
          sortable: true,
        },
        {
          label: "Creator ID",
          field: "CreatorId",
          width: "3%",
          sortable: true,
        },
        {
          label: "Status",
          field: "Status",
          width: "3%",
          sortable: true,
        },
        {
          label: "Operation",
          field: "Operation",
          width: "10%",
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "id",
        sort: "asc",
      },
      messages: {
        pagingInfo: "Showing {0}-{1} of {2}",
        pageSizeChangeLabel: "Row count:",
        gotoPageLabel: "Page:",
        noDataAvailable: "No data",
      },
    });

    let tableData = [];
    let showTableData = [];
    const searchTerm = ref('')

    return {
      token,
      table,
      tableData,
      showTableData,
      searchTerm,
      router
    };
  },

  methods: {
    doSearch (offset, limit, order, sort) {
      this.table.isLoading = true;
      setTimeout(() => {
        this.table.isReSearch = offset == undefined ? true : false;
        limit = offset + limit;
        this.table.rows = this.getTableData(offset, limit, order, sort);
        this.table.totalRecordCount = this.tableData.length;
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      }, 600);
    },

    getTableData (offst, limit, order, sort) {
      let data = [];
      let sliceData = this.showTableData.slice(offst, limit)
      if (sort == 'asc') {
        sliceData.sort((a, b) => (a[order] > b[order]) ? 1 : -1)
      }
      else {
        sliceData.sort((a, b) => (a[order] > b[order]) ? -1 : 1)
      }
      for (let i = 0; i < sliceData.length; i++) {
        data.push({
          JobId: sliceData[i].JobId,
          JobName: sliceData[i].JobName,
          Date: sliceData[i].CreateDate,
          GroupName: sliceData[i].GroupName,
          CreatorId: sliceData[i].CreatorId,
          Status: sliceData[i].Status
        });
      }
      return data;
    },

    tableLoadingFinish (elements) {
      this.table.isLoading = false;
    },

    updateCheckedRows (rowsKey) {
      console.log(rowsKey);
    },

    filterSearch () {
      if (this.searchTerm != '' && this.searchTerm !== undefined) {
        this.showTableData = this.tableData.filter(
          (x) =>
            x.JobId.toString().toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            x.JobName.toString().toLowerCase().includes(this.searchTerm.toLocaleLowerCase()) ||
            x.CreatorId.toString().toLowerCase().includes(this.searchTerm.toLowerCase()) 
        )
        this.doSearch(0, 10, "JobId", "asc")
      }
      else if(this.searchTerm == '') {
        this.showTableData = this.tableData
        this.doSearch(0, 10, "JobId", "asc")
      }
    },

    clickView (jobid) {
      this.router.push('/jobdetail/' + jobid.toString())
    },

    clickMap (jobid) {
      const url = `${mapIP}/?jobId=${jobid}`;
      window.open(url, '_blank');
    },
    
    toggleDeleteJob() {
      this.showDeleteJob = !this.showDeleteJob
    },

    async clickDeleteJob () {
      console.log('click delete with job id: ', this.deleteJobId)
      const response = await deleteJobfunc(this.token, this.deleteJobId)
      this.toggleDeleteJob()
      this.tableData = await getMyJobData(this.ID, this.api)
      this.tableData = this.tableData.data
      this.showTableData = this.tableData
      this.doSearch(0, 10, "JobId", "asc");
    },

    async clickDelete(jobId) {
      this.deleteJobId = jobId
      this.toggleDeleteJob()
    },

    clickCancel () {
      this.toggleDeleteJob()
    },

    async clickSolved (jobId) {
      await solvedJobFunc(this.token, jobId)
      this.tableData = await getMyJobData(this.ID, this.api)
      this.tableData = this.tableData.data
      this.showTableData = this.tableData
      this.doSearch(0, 10, "JobId", "asc");
    }

  },

  async mounted() {
    this.tableData = await getMyJobData(this.ID, this.api)
    this.tableData = this.tableData.data
    this.showTableData = this.tableData
    this.doSearch(0, 10, "JobId", "asc");
  }
});

</script>

<style scoped>

</style>