<template>
    <div class="inputBox">
        <div class="inputKey">
            {{ inputKey }}
            <div class="detailDiv" v-if="showDetailToggle" @mouseover="showText = true" @mouseleave="showText = false">
                <img class="detailImg" src="@/assets/questionMark.svg"/>
                <Transition>
                    <div class="detailBox" v-if="showText">
                        {{ details }}
                    </div>
                </Transition>
            </div>
        </div>
        <div class="inputValue"> 
            <input class="inputComp" :type="inputType" :placeholder="inputKey" @input="getInputValue" v-model="userInputValue"/>
        </div>
        <div class="inputUnit">
            {{ inputUnit }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'inputBox',
    props: {
        inputKey: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            default: 'text'
        },
        inputUnit: {
            type: String,
            default: ''
        },
        details: {
            type: String,
            default: ''
        },
        userInputValue: ''
    },
    data() {
        return {
            showDetailToggle: false,
            showText: false
        }
    },
    methods: {
        getInputValue(e) {
            this.inputValue = e.target.value
            this.$emit('emitInputValue', this.inputValue)
        },
    },
    setup() {
        
    },
    mounted() {
        if(this.details == ''){
            this.showDetailToggle = false
        }
        else {
            this.showDetailToggle = true
        }
        if (this.userInputValue != '' ) {
            this.inputValue = this.userInputValue
            this.$emit('emitInputValue', this.inputValue)
        }
    }
}
</script>

<style scoped>
.inputBox{
    height: 29px;

    padding-left: 24px;
    padding-right: 24px;
    padding-top: 15px;
    padding-bottom: 16px;

    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    font-size: 18px; /*.875rem*/
}

.inputKey{
    display: flex;
    align-items: center;
    flex-basis: 140px;
    margin-right: 24px;
    padding-top: 4px;
}
.detailDiv{
    padding-left: 5px;
    padding-top: 4px;
    z-index: 99;
    font-size: 12px;
}
.detailImg{
    width: 16px;
}
.detailDiv:hover .detailBox{
    display: block;
}
.detailBox{
    display: none;
    position: absolute;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
.inputValue{
    flex-basis: 190px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 24px;
    text-align: left;
    /* padding-top: 4px; */
}
.inputComp{
    width: 100%; 
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
}
.inputUnit{
    display: flex;
    align-items: center;
    flex-basis: 50px;
    /* margin-right: 24px; */
    padding-top: 4px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media (max-width: 500px) {
    .inputBox{
        padding-left: 8px;
        padding-right: 8px;
    }
}
</style>