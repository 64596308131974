<template>
    <div class='searchDiv' style="text-align: left">
        <input class="input-style" v-model="searchTerm" placeholder="Filter by Plant ID, Name or Creator ID"/>
        <button class="buttonStyle" @click="filterSearch">Search</button>
    </div>
      
    <table-lite class="datatable"
        :is-slot-mode="true"
        :is-loading="table.isLoading"
        :is-re-search="table.isReSearch"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :messages="table.messages"
        @do-search="doSearch"
        @is-finished="tableLoadingFinish"
        @return-checked-rows="updateCheckedRows"
    >
        <template v-slot:Operation="data">
            <button type="button" @click="clickView(data.value.GroupId)">Switchboards</button>
            <button type="button" v-if="data.value.IsAuth" @click="clickManage(data.value.GroupId)">Manage</button>
            <button type="button" v-if="data.value.IsAuth" @click="clickDelete(data.value.GroupId)">Delete</button>
        </template>
    </table-lite>
</template>
  
<script>
import { defineComponent, reactive, ref} from "vue";
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies";
import { ElMessageBox } from 'element-plus'
import { hostIP } from "@/js/constants";

import TableLite from "vue3-table-lite";

let { cookies } = useCookies();

async function getGroupData(token) {
    const msg = {
        "token": token,
    }
    const request = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(msg)
    }
    const response = await fetch(`${hostIP}/get_user_groups`, request)
    const is_success = (response.status === 200) ? true : false;
    const data = [];
    if (is_success){
        const response_json = await response.json()
        const groups_data = JSON.parse(response_json.groups_data)
        const n_row = response_json.n_data
        for (let i=0; i<n_row; i++) {
            data.push({
                GroupId: groups_data.GroupId[i],
                GroupName: groups_data.GroupName[i],
                CreatorId: groups_data.CreatorId[i],
                IsAuth: groups_data.isAuth[i]
            })
        }
    }
    return {
        data
    }
}
  
export default defineComponent({
name: "groupTable",
components: { 
    TableLite,
},

props: {
    isAuth: {
        type: Boolean,
        default: false
    }
},

emits: [
    'emitJobId'
],

data () {
    return{

    }
},

setup() {
    const router = useRouter()
    let token = ''
    token = cookies.get('seeng-access-token')

    const table = reactive({
        isLoading: false,
        isReSearch: false,
        columns: [
            {
                label: "Plant ID",
                field: "GroupId",
                width: "3%",
                sortable: true,
                isKey: true,
            },
            {
                label: "Plant",
                field: "GroupName",
                width: "3%",
                sortable: true,
            },
            {
                label: "Creator ID",
                field: "CreatorId",
                width: "10%",
                sortable: true,
            },
            {
                label: "Operation",
                field: "Operation",
                width: "10%",
            },
        ],
        rows: [],
        totalRecordCount: 0,
        sortable: {
            order: "id",
            sort: "asc",
        },
        messages: {
            pagingInfo: "Showing {0}-{1} of {2}",
            pageSizeChangeLabel: "Row count:",
            gotoPageLabel: "Page:",
            noDataAvailable: "No data",
        },
    });

    let tableData = [];
    let showTableData = [];
    const searchTerm = ref('')

    return {
        token,
        router,
        table,
        tableData,
        showTableData,
        searchTerm,
    };
},

methods: {
    doSearch (offset, limit, order, sort) {
        this.table.isLoading = true;
        setTimeout(() => {
            this.table.isReSearch = offset == undefined ? true : false;
            limit = offset + limit;
            this.table.rows = this.getTableData(offset, limit, order, sort);
            this.table.totalRecordCount = this.tableData.length;
            this.table.sortable.order = order;
            this.table.sortable.sort = sort;
        }, 600);
    },

    getTableData (offst, limit, order, sort) {
        let data = [];
        let sliceData = this.showTableData.slice(offst, limit)
        if (sort == 'asc') {
            sliceData.sort((a, b) => (a[order] > b[order]) ? 1 : -1)
        }
        else {
            sliceData.sort((a, b) => (a[order] > b[order]) ? -1 : 1)
        }
        for (let i = 0; i < sliceData.length; i++) {
            data.push({
                GroupId: sliceData[i].GroupId,
                GroupName: sliceData[i].GroupName,
                CreatorId: sliceData[i].CreatorId,
                IsAuth: sliceData[i].IsAuth
            });
        }
        return data;
    },

    tableLoadingFinish (elements) {
        this.table.isLoading = false;
    },

    updateCheckedRows (rowsKey) {
        console.log(rowsKey);
    },

    filterSearch () {
        if (this.searchTerm != '' && this.searchTerm !== undefined) {
            this.showTableData = this.tableData.filter(
            (x) =>
                x.GroupId.toString().toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                x.GroupName.toString().toLowerCase().includes(this.searchTerm.toLocaleLowerCase()) ||
                x.CreatorId.toString().toLowerCase().includes(this.searchTerm.toLowerCase()) 
            )
            this.doSearch(0, 10, "GroupId", "asc")
        }
        else if(this.searchTerm == '') {
            this.showTableData = this.tableData
            this.doSearch(0, 10, "GroupId", "asc")
        }
    },

    clickView (groupId) {
        this.router.push('/groupjob/' + groupId.toString())
    },

    clickManage (groupId) {
        this.router.push('/managegroup/' + groupId.toString())
    },

    async clickDelete (groupId) {
        const msg = {
            "token": this.token,
            "group_id": groupId
        }
        const request = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(msg)
        }
        const response = await fetch(`${hostIP}/delete_group`, request)
        const is_success = ref(false)
        is_success.value = (response.status === 200) ? true : false;
        if (is_success) {
            const data = await response.json();
            if (data.is_success){
                this.tableData = await getGroupData(this.token)
                this.tableData = this.tableData.data
                this.showTableData = this.tableData
                this.doSearch(0, 10, "GroupId", "asc");
            }
            else {
                is_success.value = false
            }
        }
        if (is_success) {
            ElMessageBox.alert('Delete group success', 'Message', {
                confirmButtonText: 'OK',
            })
        }
        else {
            ElMessageBox.alert('Delete group fail', 'Warning', {
                confirmButtonText: 'OK',
            })
        }
    }

},

async mounted() {
    this.tableData = await getGroupData(this.token)
    this.tableData = this.tableData.data
    this.showTableData = this.tableData
    this.doSearch(0, 10, "GroupId", "asc");
}
});

</script>

<style scoped>
</style>