<template>
  <Menu active_idx="my_job"/>
  <MyJob/>
</template>

<script>
// @ is an alias to /src
import MyJob from '@/components/MyJob.vue'
import Menu from '@/components/Menu.vue'

export default {
  name: 'MyJobView',
  components: {
    Menu,
    MyJob
  }
}
</script>
