// export const platformIP = 'http://localhost:8080';
// export const hostIP = 'http://146.169.27.191:3000/api';
// export const loginIP = 'http://localhost:7001';
// export const mapIP = 'http://localhost:5173';

export const platformIP = 'https://seeng.w3ddns.it';
export const hostIP = 'https://seeng.w3ddns.it/api';
export const loginIP = 'https://authseeng.w3ddns.it';
export const mapIP = 'https://seeng.w3ddns.it/map';

export const clientId = '2225151a462eeac4c124';

// export const hostIP = 'https://seeng.w3ddns.it/api/'

export const pwRule = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
