<template>
    <div class="inputBox">
        <div class="inputKey">
            {{ inputKey }}
        </div>
        <div class="inputValue"> 
            <select class="inputComp" v-model="selectedValue" @input="getInputValue">
                <option v-for="option in group_options" :key="option.text" :value="option.key">
                    {{ option.text }}
                </option>
            </select>
        </div>
        <div class="inputUnit">
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useCookies } from "vue3-cookies";

import { getUserGroup } from "@/js/asyncFunctions"

let { cookies } = useCookies();

export default {
    name: 'selectGroup',
    props: {
        inputKey: {
            type: String,
            default: 'Select Plant',
        },
        initSelectedValue: {
            type: String,
            default: ''
        }
    },

    // emits: [
    //     'update:selectedValue',
    // ],


    data() {
        return {
            selectedValue: ''
        }
    },
    methods: {
        getInputValue(e) {
            this.inputValue = e.target.value
            this.$emit('emitInputValue', this.inputValue)
        },
    },
    setup() {
        let token = ''
        token = cookies.get('seeng-access-token')
        
        const group_options = ref(null)

        const fetchData = async () => {
            return await getUserGroup(token)
        }

        onMounted(async () => {
            group_options.value = await fetchData()
        })
        
        return {
            group_options
        }
    },
    mounted() {
        this.selectedValue = this.initSelectedValue
    }
}
</script>

<style scoped>
.inputBox{
    height: 29px;

    padding-left: 24px;
    padding-right: 24px;
    padding-top: 15px;
    padding-bottom: 16px;

    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    font-size: 18px; /*.875rem*/
}

.inputKey{
    display: flex;
    align-items: center;
    flex-basis: 140px;
    margin-right: 24px;
    padding-top: 4px;
}
.inputValue{
    flex-basis: 190px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 24px;
    text-align: left;
    /* padding-top: 4px; */
}
.inputComp{
    width: calc(100% + 5px); 
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
}
.inputUnit{
    display: flex;
    align-items: center;
    flex-basis: 50px;
    /* margin-right: 24px; */
    padding-top: 4px;
}

@media (max-width: 500px) {
    .inputBox{
        padding-left: 8px;
        padding-right: 8px;
    }
}
</style>