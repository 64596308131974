<template>
<div id="titleDiv">
      Create New Plant
</div>
<div style="width: 100%; position: relative; float: left;">
    <div class="formContainer">
        <div id="addGroupDiv">
            <inputBox inputKey="Plant Name" @emitInputValue="getGroupName"/>
        </div>
    </div>
</div>
<div class="buttonDiv">
    <button @click="clickGroupJob">Add Plant</button>
    <button @click="clickCancel">Cancel</button>
</div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { useCookies } from "vue3-cookies"
import { hostIP } from "@/js/constants";

import inputBox from '@/components/InputBox.vue'

let { cookies } = useCookies();

export default {
name: "addNewGroup",
components: {
    inputBox
},
props: {
    addGroupToggle: Boolean  // use to show this window or not
},
data() {
    return {
        groupName: ''
    }
},
emits: [
    'update:addGroupToggle',
],

setup() {
    let token = ''
        token = cookies.get('seeng-access-token')
    return {
        token
    }
},

methods: {
    getGroupName (value) {
        this.groupName = value
    },

    emitAddGroupToggle (){
        this.$emit('update:addGroupToggle', !this.addGroupToggle)
    }, 

    async clickGroupJob () {
        if (this.groupbName == ''){
            ElMessageBox.alert('Please specify plant name', 'Warning', {
                confirmButtonText: 'OK',
            })
        }
        else {
            const msg = {
                "token": this.token,
                "group_name": this.groupName,
            }
            const request = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(msg)
            }
            const response = await fetch(`${hostIP}/create_new_group`, request)
            const is_success = (response.status === 200) ? true : false;
            if (is_success) {
                ElMessageBox.alert('Add plant successfully', 'Message', {
                    confirmButtonText: 'OK',
                })
                this.emitAddGroupToggle()
            }
            else {
                ElMessageBox.alert('Fail creating plant', 'Warning', {
                    confirmButtonText: 'OK',
                })
            }
        }
    },

    clickCancel () {
        this.emitAddGroupToggle()
    },
},

}
</script>

<style>
#titleDiv {
    font-size: 24px;
    margin-top: 10px;
}

.formContainer{
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid black;
    border-radius: 5px;
    width: 85%;
    margin-top: 20px;
    margin-bottom: 20px;
}

#addGroupDiv {
    float: left;
    position: relative;
    width: calc(99% - 260px);

    margin-top: 10px;
    margin-bottom: 20px;
}

.buttonDiv{
    position: relative;
    float: left;
    width: 100%;
}
</style>