import { createRouter, createWebHistory } from 'vue-router'
import MyJobView from '../views/MyJobView.vue'
import WarningJobView from '../views/WarningJobView.vue'
import GroupsListView from '../views/GroupsListView.vue'
import AuthCallback from '../views/AuthCallback.vue'
import NotFound from '../views/NotFound.vue'
import { useCookies } from "vue3-cookies"
import { platformIP } from '../js/constants'
let { cookies } = useCookies();


const routes = [
  {
    path: '/',
    name: 'home',
    component: GroupsListView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/my_job',
    name: 'my_job',
    component: MyJobView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/warn_job',
    name: 'warn_job',
    component: WarningJobView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/groups',
    name: 'groups',
    component: GroupsListView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/callback',
    name: 'AuthCallback',
    component: AuthCallback,
    meta: { requiresAuth: false } 
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/jobdetail/:jobId',
    name: 'jobdetail',
    component: () => import(/* webpackChunkName: "profile" */ '../views/JobDetailView.vue'),
    props: (route) => ({
      jobId: route.params.jobId,
    }),
    meta: { requiresAuth: true } 
  },
  {
    path: '/groupjob/:groupId',
    name: 'groupjob',
    component: () => import(/* webpackChunkName: "profile" */ '../views/GroupJobView.vue'),
    props: (route) => ({
      groupId: route.params.groupId,
    }),
    meta: { requiresAuth: true } 
  },
  {
    path: '/managegroup/:groupId',
    name: 'managegroup',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ManageGroupView.vue'),
    props: (route) => ({
      groupId: route.params.groupId,
    }),
    meta: { requiresAuth: true } 
  },
  {
    path: '/adminalljob',
    name: 'adminalljob',
    component: () => import(/* webpackChunkName: "profile" */ '../views/AdminAllJobView.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/adminalluser',
    name: 'adminalluser',
    component: () => import(/* webpackChunkName: "profile" */ '../views/AdminAllUserView.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/adminallgroup',
    name: 'adminallgroup',
    component: () => import(/* webpackChunkName: "profile" */ '../views/AdminAllGroupView.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/:pathMatch(.*)*', // catch-all route for undefined paths
    name: 'NotFound',
    component: NotFound,
    beforeEnter: (to, from, next) => {
      document.title = "404 - Not Found";  // Optional: Set page title for 404
      next();
    },
    meta: { requiresAuth: false } 
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const isAuthenticated = cookies.isKey('seeng-access-token');

  if (requiresAuth && !isAuthenticated) {
    console.log('not authenticated');
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router
