<template>
  <div class="container">
    <div v-if="!addJobToggle">
      <button class="addButton" style="float:right" @click="clickAddJob">Add Switchboard</button>
      <div v-if="userId">
        <myTable :ID="userId" api="get_my_job_data" />
      </div>
    </div>

    <div v-if="addJobToggle">
      <addNewJob v-model:addJobToggle="addJobToggle" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useCookies } from "vue3-cookies"
import { getUserIdAuth } from '@/js/asyncFunctions';

import myTable from "@/components/MyTable.vue"
import addNewJob from "@/components/AddNewJob.vue"

let { cookies } = useCookies();

export default {
  name: 'MyJob',
  props: {

  },
  components: {
    myTable,
    addNewJob,
  },

  data() {
    return {
      addJobToggle: false,
    }
  },

  setup() {
    let token = ''
    token = cookies.get('seeng-access-token')

    const userId = ref(null)

    const fetchData = async () => {
      return await getUserIdAuth(token)
    }

    onMounted(async () => {
      const res = await fetchData()
      userId.value = res.ID
    })

    return {
      userId,
    }
  },

  methods: {
    clickAddJob() {
      this.addJobToggle = !this.addJobToggle
    }
  },

  mounted() {

  },
}
</script>

<style>
#myJobDiv {
  position: relative;
  float: left;
  width: calc(100% - 210px);
  margin-left: 5px;
}
</style>
