<template>
    <div class="Header">
        <img id="logo_image" src="@/assets/logo.png" @click="this.router.push('/')"/>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
    name: 'Header',
    setup() {
        const router = useRouter()
        return {
            router    
        }
    },
    methods: {

    }
}
</script>

<style>
.Header {
    width: 100%;
    height: 70px;
    background: #545c64;
    overflow: hidden;
    position: relative;
}

#logo_image {
    position: relative;
    /* display: flex; */
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 75px;
    cursor: pointer;
    z-index: 5000;
}

@media (max-width: 500px) {
    .Header {
        height: 50px;
    }
    #logo_image {
        height: 50px;
    }   
}
</style>