<template>
  <Header/>
  <router-view/>
</template>

<script>
import { useRouter } from 'vue-router'

import Header from "@/components/Header.vue"
export default {
  components: {
    Header,
},
  setup() {
    const router = useRouter()
    return {
      router
    }
  },
  mounted() {
    document.title = "Simon";
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .01428571em;
  font-weight: 400;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

button {
    text-align: center;
    min-width: 100px;
    padding: 6px 18px;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 5px;
    margin-bottom: 10px;
    border: 3px;
    border-radius: 5px;
    
    letter-spacing: .01428571em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    color: white;
    background-color: rgb(74,143,234);

    cursor: pointer;

    -webkit-transition-duration: 0.4s; 
    transition-duration: 0.4s;
}

button:hover{
    background-color: rgb(15, 110, 235);
    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.24), 0 9px 25px 0 rgba(0,0,0,0.19);
}

input {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
}

select {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
}

.container {
  position: relative;
  float: left;
  width: calc(100% - 186px);
  left: 170px;
  margin-top: 8px;
  margin-left: 8px;
}

.mask{
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.6);
    z-index: 2000;
}

.messageBox{
    position: fixed;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    border: 1px solid rgb(218,220,224);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
}

.message{
  margin-left: 30px;
  margin-right: 30px
}

.searchDiv {
  width: 80%;
  position: relative;
  float: left;
}

.input-style {
  width: 40%;
  border: 2px solid black;
  border-radius: 4px;
  font-size: 18px;
  margin: 5px;
  height: 26px;
}
.search-style {
    min-width: 81px;
    border: 3px;
    border-radius: 5px;

    letter-spacing: .01428571em;
    font-family: Roboto,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: white;
    background-color: rgb(74,143,234);

    cursor: pointer;
}

.datatable {
  width: 100%;
}

.addButton {
  margin-left: 0px;
}

@media (max-width: 1200px) {
  .container {
    width: calc(100% - 16px);
    left: 0px;
  }
  .searchDiv {
    width: calc(100% - 130px);
  }
  .input-style {
    width: calc(100% - 150px)
  }
}

@media (max-width: 500px) {
  .container {
    width: calc(100% - 4px);
    left: 0px;
    margin-left: 2px;
  }

  .searchDiv {
    width: calc(100% - 16px);
  }

  .input-style {
    width: 100%;
  }
  .buttonStyle{
    margin-left: 4px;
  }
  .addButton{
    position: absolute;
    top: 37px;
    z-index: 800;
    right: 2px;
  }
  .datatable {
    font-size: 16px;
  }
}
</style>
