<template>
    <Menu active_idx="warn_job"/>
    <warningJob/>
  </template>
  
  <script>
  // @ is an alias to /src
  import warningJob from '@/components/WarningJob.vue'
  import Menu from '@/components/Menu.vue'
  
  export default {
    name: 'WarningJobView',
    components: {
      Menu,
      warningJob
    }
  }
  </script>
  